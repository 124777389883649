<footer class="flex align-items-center" style="z-index: 1">
        <div class="flex-none flex pl-1 align-items-center">
            <brand-logo logoHeight="24"></brand-logo>
        </div>
    
        <div class="flex-none flex pl-2">
            <span class="trademark-text">© {{year}}, Operational Excellence (OPEX) Group Ltd, all rights reserved. v{{version}}</span>
        </div>
    
        <div class="flex-grow-1 flex"></div>
    
        <div class="flex-none flex justify-content-end">
            <a target='_blank' href="https://www.opex-group.com/privacy-policy" class="mr-2">Privacy Policy </a>
            <a target='_blank' href="/account/cookie" class="mr-2">Cookies Policy </a>
            <a target='_blank' href="/account/terms" class="mr-2">Terms of Use</a>
        </div>
</footer>