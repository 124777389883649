
<!-- <div class="flex p-2">
    <div class="flex-1 flex align-items-center justify-content-center">
        <p-message severity="warn" text="You are not authorised to perform this action!"></p-message>
    </div>
</div> -->

<div class="page-header-panel flex flex-row flex-wrap p-3 mb-4">
    <div class="flex flex-1 flex-wrap align-content-center">
        <div class="flex flex-column flex-1 m-1 justify-content-center">
            <div class="flex flex-column mb-2">
                <h3>Unauthorised</h3>
            </div>
            <div class="flex flex-column">
                You are not authorised to access this page.  If you require access, please fill in the form below.
            </div>
        </div>
    </div>
</div>

<div class="grid">
    <div class="col-6 col-offset-3">
        <p-card>
            <ng-template pTemplate="content">
                <div class="formgrid grid">
                    <div class="field col-12">
                        <label for="tenant">Tenant</label>
                        <input #tenant="ngModel" id="tenant" type="text" pInputText [(ngModel)]="requestAssetAccess.tenant"
                            class="inputfield w-full" [ngClass]="{'ng-dirty': (!requestAssetAccess.tenant && submitted)}"
                            placeholder="Tenant Name">
                    </div>
                    <div class="field col-12">
                        <label for="asset">Asset</label>
                        <input #asset="ngModel" id="asset" type="text" pInputText [(ngModel)]="requestAssetAccess.asset"
                            class="inputfield w-full" [ngClass]="{'ng-dirty': (!requestAssetAccess.asset && submitted)}"
                            placeholder="Asset">
                        <small *ngIf="(asset.invalid && submitted)" class="p-error">The name of the asset you are requesting access too is required.</small>
                    </div>
                    <div class="field col-12">
                        <label for="reason">Reason</label>
                        <textarea id="reason" pInputTextarea [(ngModel)]="requestAssetAccess.reason" rows="5"
                            placeholder="Access request reason" class="w-full"></textarea>
                        <small *ngIf="(!requestAssetAccess.reason && submitted)" class="p-error">Access request reason are required.</small>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <div class="flex justify-content-end">
                    <p-button icon="pi pi-envelope" (click)="submitRequest()" label="Submit" styleClass="p-button-success"></p-button>
                </div>
            </ng-template>
        </p-card>
    </div>
</div>

<p-toast></p-toast>