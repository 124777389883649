export enum InsightStatus {
	NotSet = 'Not Set',
	Draft = 'Draft',
	Requested = 'Requested',
    Rejected = 'Rejected',
    Submitted = 'Submitted',
    Accepted = 'Accepted',
    Completed = 'Completed',
    Deleted = 'Deleted'
}
