import { Component, Input } from '@angular/core';
import { AssetModel } from '../../models/tenant/location/asset-model';

@Component({
  templateUrl: './brand.component.html',
  selector: 'brand-logo'
})
export class BrandComponent{
  
    @Input() logoHeight: string = "64";
    @Input() tenantAssets: AssetModel[] = [];
    @Input() tenantCount: number = 0;
  
    constructor() {}
}
