import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { empty, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSpinnerComponent } from '../../components/app-spinner/app-spinner.component';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(public router: Router, private spinner: AppSpinnerComponent) { }

    /**
     * The HTTP Interceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add the error handler to the request
        return next.handle(req)
            //.pipe(finalize(() => this.spinner.hide()))
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) {
                        console.log('This is client side error');
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        console.log('This is server side error');
                        errorMsg = `Error Code: ${error.status},  Message: ${error.error}`;

                        if (error.status === 403) {
                            // Request was forbidden.
                            console.error('Forbidden request.');
                            let urlSections = req.url.split('/');
                            Swal.fire('Forbidden', `You are not authorised to access ${urlSections[urlSections.length - 1]}.`, 'error');
                        }
                    }
                    console.log(errorMsg);
                    return throwError(error);
                })
            );
    }
}