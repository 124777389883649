import { Component, Inject, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { OptixComponentBase } from './core/utils/base-components/optix-component-base';
import { LogUpdateService } from './core/services/service-worker/log-update.service';
import { PromptUpdateService } from './core/services/service-worker/prompt-update.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { Observable, filter, lastValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends OptixComponentBase implements OnInit {
  title = 'optix 2';

  constructor(private primengConfig: PrimeNGConfig, private logUpdateService: LogUpdateService, private promptUpdateService: PromptUpdateService) {
    super();
    this.logDebug(this.constructor.name, 'loaded');
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.logDebug(this.ngOnInit.name, 'loaded');
  }

  public reloadApp(): void {
		// Reload the page to update to the latest version.
		document.location.reload();
  }
}
