import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RequestAccessModel } from 'src/app/core/models/global/clients/request-access.model';
import { SupportService } from 'src/app/core/services/global/clients/support.service';
import { AppComponentBase } from 'src/app/core/utils/base-components/app-component-base';

@Component({
  selector: 'app-root',
  templateUrl: './unauthorised.component.html',
})
export class UnauthorisedComponent extends AppComponentBase implements OnInit, OnDestroy {
  
  private supportService: SupportService = inject(SupportService);

  protected tenant: string = '';
  protected asset: string | undefined = undefined;

  public requestAssetAccess: RequestAccessModel = new RequestAccessModel();
  public submitted: boolean = false;

  constructor() {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    // Get the requested tenant and asset from the session
    this.tenant = this.appOktaSessionService.requestedTenant;
    this.asset = this.appOktaSessionService.requestedAsset;

    // Set the tenant initially in the request model
    this.requestAssetAccess.tenant = this.tenant;
    // Append the asset if it exists
    if (this.asset) {
      this.requestAssetAccess.asset = this.asset;
    }
  }

  public override ngOnDestroy(): void {
    this.logDebug(this.ngOnDestroy.name, 'Unauthorised OnDestroy');
    // Unsubscribe from all subscriptions
    this.sessionStateSubscription.unsubscribe();
    this.pageSubscriptions.unsubscribe();

    super.ngOnDestroy();
  }

  public submitRequest(): void {
    this.logDebug(this.submitRequest.name, 'submitting access request');
    this.submitted = true;
    // Validate the support request
    if (this.requestAssetAccess.asset && this.requestAssetAccess.reason) {
        this.spinner.show();
        // Update the requestor details
        this.requestAssetAccess.requestorEmail = this.sessionInfo.email ?? '';
        this.requestAssetAccess.requestorFirstname = this.sessionInfo.firstname ?? '';
        this.requestAssetAccess.requestorLastname = this.sessionInfo.lastname ?? '';

        this.supportService.submitAccessRequest(this.requestAssetAccess)
            .subscribe({
                next: (r) => {
                    this.submitted = false;
                    this.requestAssetAccess = new RequestAccessModel();
                    this.showToast('success', 'Submitted', 'The access request has been submitted.');
                },
                error: (e) => { this.showToast('error', 'Error', 'The access request could not be submitted - ' + e) }
            });
    }
}
}
