import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TenantModel } from '../../models/global/clients/tenant-model';
import { AssetModel } from '../../models/tenant/location/asset-model';
import { MenuService } from '../../services/session/menu/menu.service';
import { OptixComponentBase } from '../../utils/base-components/optix-component-base';
import { ScreenService } from '../../services/screen.service';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  templateUrl: './menu.component.html',
  selector: 'menu-bar'
})
export class MenuComponent extends OptixComponentBase implements OnChanges, AfterViewInit {
  
    @Input() menuVisible: boolean;
    @Output() menuVisibleChange: EventEmitter<boolean> = new EventEmitter();

    @Input() tenant?: TenantModel;
    @Input() asset?: AssetModel;
  
    public menuItems!: MenuItem[];

    public isSmallMediumScreen: boolean = false;
  
    constructor(private menuService: MenuService, private screenService: ScreenService) {
        super();
        this.menuVisible = false;
    }
  
    ngOnChanges(changes: SimpleChanges): void {
        this.logDebug(this.ngOnChanges.name, 'Entering...', [changes]);
        // If the tenant or asset has changed, refresh the menu
        if (changes['tenant'] || changes['asset']) {
            this.logInfo(this.ngOnChanges.name, 'Refreshing menu after change to tenant or asset', [changes]);
            this.menuItems = this.menuService.getMenuItems(() => { this.onMenuItemSelected(); });
        }
        this.logDebug(this.ngOnChanges.name, 'Leaving...');
    }

    ngAfterViewInit(): void {
        this.logDebug(this.ngAfterViewInit.name, 'Entering...');
        // Subscribe to if the screen size changes
        this.screenService.isBelowLg().subscribe((result: BreakpointState) => {
            this.isSmallMediumScreen = result.matches;
        });
        this.logDebug(this.ngAfterViewInit.name, 'Leaving...');
    }

    public onMenuItemSelected(): void {
        this.logDebug(this.onMenuItemSelected.name, 'Entering...');
        if (this.isSmallMediumScreen) {
            this.onHideSideBar();
        }
        this.logDebug(this.onMenuItemSelected.name, 'Leaving...');
    }
  
    public onHideSideBar(): void {
        this.logDebug(this.onHideSideBar.name, 'Entering...');
        this.menuVisible = false;
        this.menuVisibleChange.emit(this.menuVisible);
        // Animate the main content to the left
        this.screenService.removeMarginForMenuPanel();
        this.logDebug(this.onHideSideBar.name, 'Leaving....');
    }
}
