import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { share, Subject } from "rxjs";
import { OptixComponentBase } from "../utils/base-components/optix-component-base";

export class PendingRequest {
    constructor(
        public url: string,
        public method: string,
        public data: any,
        public subscription: Subject<any>
    ) { }
}

@Injectable({ providedIn: 'root' })
export class HttpQueue extends OptixComponentBase {
    private httpClient: HttpClient;
    private requests$ = new Subject<any>();
    private queue: PendingRequest[] = [];

    constructor() {
        super();

        this.httpClient = inject(HttpClient);

        this.requests$.subscribe((request) => this.execute(request));
    }

    public get<t>(url: string, cancelDuplicates: boolean = true, clearQueue: boolean = false) {
        // Clear the queue if required
        if (clearQueue)
            this.clearQueue();
        // Make the new request
        return this.addRequestToQueue(url, 'get', null, cancelDuplicates);
    }

    public post<t>(url: string, data: any, cancelDuplicates: boolean = true, clearQueue: boolean = false) {
        // Clear the queue if required
        if (clearQueue)
            this.clearQueue();
        // Make the new request
        return this.addRequestToQueue(url, 'post', data, cancelDuplicates);
    }

    public put<t>(url: string, data: any, cancelDuplicates: boolean = true) {
        return this.addRequestToQueue(url, 'put', data, cancelDuplicates);
    }

    public delete<t>(url: string, cancelDuplicates: boolean = true) {
        return this.addRequestToQueue(url, 'delete', null, cancelDuplicates);
    }

    private execute(requestData: PendingRequest) {
        this.logDebug(this.execute.name, 'executing request', [requestData]);
        // Execute the request
        switch (requestData.method) {
            case 'post':
                this.httpClient.post(requestData.url, requestData.data).subscribe({
                    next: (res) => { this.requestExecuted(requestData, res); },
                    error: (e) => { this.requestError(requestData, e); }
                });
                break;
            case 'put':
                this.httpClient.put(requestData.url, requestData.data).subscribe({
                    next: (res) => { this.requestExecuted(requestData, res); },
                    error: (e) => { this.requestError(requestData, e); }
                });
                break;
            case 'delete':
                this.httpClient.delete(requestData.url).subscribe({
                    next: (res) => { this.requestExecuted(requestData, res); },
                    error: (e) => { this.requestError(requestData, e); }
                });
                break;
            default:
                this.httpClient.get(requestData.url).pipe(share()).subscribe({
                    next: (res) => { this.requestExecuted(requestData, res); },
                    error: (e) => { this.requestError(requestData, e); }
                });
        }
    }

    private requestExecuted(requestData: PendingRequest, res: any) {
        const sub = requestData.subscription;
        
        if (sub && res) {
            sub.next(res);
            sub.complete();
        }
        
        this.queue.shift();
        this.startNextRequest();
    }

    private requestError(requestData: PendingRequest, e: HttpErrorResponse) {
        let responseError: any = e;
        // For unauthorised or internal server errors, replace the message with more user friendly messages
        if (e.status === 403) {
            responseError = {
                error: {
                    title: 'You are not authorised to perform this action.  Please contact your administrator if you think you should have access.'
                },
                headers: e.headers,
                status: e.status
            };
        }
        if (e.status === 408) {
            responseError = {
                error: {
                    title: 'The server timed out retrieving data. Please wait a moment, and try again by using the Update button. If the problem persists, please contact support.',
                },
                headers: e.headers,
                status: e.status,
            };
        }
        if (e.status === 500) {
            responseError = {
                error: {
                    title: `We're sorry an unexpected error occurred and your request could not be processed.  The error has been recorded and the team are on it.`,
                },
                headers: e.headers,
                status: e.status,
            };
        }

        // Update the request
        const sub = requestData.subscription;
        sub.error(responseError);
        sub.complete();
        this.queue.shift();
        this.startNextRequest();
    }

    public clearQueue() : void {
        // Complete each of the subscriptions and then clear the queue
        for(var i = 0; i < this.queue.length; i++) {
            this.queue[i].subscription.complete();
        }
        // Clear all requests from the http queue
        this.queue = [];
    }

    private addRequestToQueue(url: string, method: string, data: any, cancelDuplicates: boolean) {
        const sub = new Subject<any>();
        const request = new PendingRequest(url, method, data, sub);

        // Check if the same request is already in the queue
        var existingRequest = this.queue.find(e => e.url === url && e.method === method && JSON.stringify(e.data) === JSON.stringify(data));
        if (existingRequest && cancelDuplicates) {
            console.log('found existing request', request, existingRequest);
            // If the request is not a position 0 (ie getting executed), complete it and remove it so it doesn't get executed
            var index = this.queue.findIndex(e => e.url === url && e.method === method && JSON.stringify(e.data) === JSON.stringify(data));
            if (index > 0) {
                existingRequest.subscription.complete();
                this.queue.splice(index, 1);
            }
        }

        this.queue.push(request);
        // If there is only 1 item in the queue, execute it
        if (this.queue.length === 1) {
            this.startNextRequest();
        }
        return sub;
    }

    private startNextRequest() {
        // get next request, if any.
        if (this.queue.length > 0) {
            this.execute(this.queue[0]);
        }
    }
}