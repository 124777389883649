import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AppComponentBase } from '../base-components/app-component-base';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard extends AppComponentBase implements CanActivate, CanActivateChild {
    
    constructor(private router: Router) { 
        super();
        this.logDebug(this.constructor.name, 'loaded');
    }

    /**
     * Checks if the token has permissions to activate the route
     * @param route The route being requested
     * @param state The route state
     * @returns true if the route can be activated
     */
    private async canActivateInternal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.logDebug(this.canActivateInternal.name, 'ensuring session is initialised', [route, state]);
        this.spinner.show();
        try {
            // Initialise the session
            await this.appOktaSessionService.init(route, state);
        
            // If the route hasn't any role data, nothing to check
            if (!route.data['role'])
                return true;

            // Check the roles match the role required for the route
            let routeRoles = route.data['role'];
            // Get the users current roles
            let session = this.appOktaSessionService.sessionState();
            if (session.roles.includes(routeRoles))
                return true;
            // User doesn't have permission to access the route, send them to unauthorised
            this.router.navigate(['forbidden']);
            return false;
        }
        finally {
            this.spinner.hide();
        }
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return await this.canActivateInternal(route, state);
    }

    public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return await this.canActivateInternal(route, state);
    }
}