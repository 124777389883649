import { Injectable } from "@angular/core";
import { OptixComponentBase } from "../../utils/base-components/optix-component-base";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "src/environments/environment";
import { SessionState } from "../../models/session/session-state.model";
import { TokenModel } from "../../models/authentication/token.model";
import { TenantModel } from "../../models/global/clients/tenant-model";
import { AssetModel } from "../../models/tenant/location/asset-model";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class AppOktaSessionHelperService extends OptixComponentBase {

    /**
     * Default constructor
     * @param _oktaStateService The okta state service
     * @param oktaAuth The okta authentication service
     */
    constructor() {
        super();
        this.logDebug(this.constructor.name, 'App Okta Session Helper Service Loaded!');
    }

    /**
     * Checks the url to see if the tenant has been set in it
     * @param route The route to check
     */
    public getTenantFromUrl(route: ActivatedRouteSnapshot): string {
        this.logDebug(this.getTenantFromUrl.name, 'started tenant url check', [route]);

        let tenant: string | null = null;

        // Check the route params initially
        tenant = route.paramMap.get('tenant');
        if (!tenant && route.children.length > 0) {
            tenant = route.children[0].paramMap.get('tenant');
        }

        // If the route param doesn't exist, check the path
        if (!tenant) {
            this.logDebug(this.getTenantFromUrl.name, 'tenant not found in route params, so checking path');
            let paths = window.location.pathname.split('/');
            // Find the main path and then take the next part as the tenant
            let mainPathIndex = paths.findIndex(e => e === 'main');
            if (mainPathIndex > -1) {
                if (paths[mainPathIndex + 1] !== 'tenant-selector')
                    tenant = paths[mainPathIndex + 1];
            }
        }

        // If the route param doesn't exist, check the domain
        if (!tenant) {
            this.logDebug(this.getTenantFromUrl.name, 'tenant not found in route params, so checking subdomain');
            let hostsplit = window.location.hostname.split('.');
            // If there is only 1 part, ie. localhost, just return it, otherwise get the subdomain
            if (hostsplit.length === 1)
                tenant = hostsplit[0];
            else
                tenant = window.location.hostname.split('.').slice(0, -2).join('.');
        }

        // Switch the tenant name if its just localhost
        if (!tenant || environment.optixSubDomains.includes(tenant.toLowerCase())) {
            tenant = 'optix';
        }

        this.logDebug(this.getTenantFromUrl.name, 'ended tenant url check', [tenant]);
        return tenant;
    }

    /**
     * Checks the url to see if the asset has been set in it
     * @param route The route to check
     */
    public getAssetFromUrl(route: ActivatedRouteSnapshot): string | undefined {
        this.logDebug(this.getAssetFromUrl.name, 'started asset url check', [route]);

        let asset: string | null = null;
        // Check the route params initially
        asset = route.paramMap.get('asset');
        if (!asset && route.children.length > 0) {
            asset = route.children[0].paramMap.get('asset');
        }

        this.logDebug(this.getAssetFromUrl.name, 'ended asset url check', [asset]);
        return asset ?? undefined;
    }

    /**
     * Checks if the requested route is for the tenant selector page
     * @param route The route to check
     */
    public isRequestedRouteForTenantSelector(route: ActivatedRouteSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForTenantSelector.name, 'checking if route is for tenant selector', [route.pathFromRoot]);
        // Check if the route is for the root module
        return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'tenant-selector') > -1;
    }

    /**
     * Checks if the requested route is for a root admin page
     * @param route The route to check
     */
    public isRequestedRouteForRootAdmin(route: ActivatedRouteSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForRootAdmin.name, 'checking if route is for root admin', [route.pathFromRoot]);
        // Check if the route is for the root module
        return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'root') > -1;
    }

    /**
     * Checks if the requested route is for a support page
     */
    public isRequestedRouteForSupport(route: ActivatedRouteSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForSupport.name, 'checking if route is for support', [route]);
        // Check if the route is for the root module
        return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'support') > -1;
    }

    /**
     * Checks if the requested route is for an admin page
     * @param route The route to check
     */
    public isRequestedRouteForAdmin(state: RouterStateSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForAdmin.name, 'checking if route is for admin', [state]);
        // Check if the route is for the root module
        //return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'admin') > -1;
        return state.url.includes('admin');
    }

    /**
     * Checks if the requested route is for the dashboard page
     * @param route The route to check
     */
    public isRequestedRouteForDashboard(state: RouterStateSnapshot): boolean {
        this.logDebug(this.isRequestedRouteForDashboard.name, 'checking if route is for dashboard', [state]);
        // Check if the route is for the root module
        //return route.pathFromRoot.findIndex(e => e.routeConfig?.path === 'dashboard') > -1;
        return state.url.includes('dashboard');
    }

    /**
     * Creates a new session state
     * @param isAuthenticated Is the user authenticated
     * @param oktaId The okta id of the user
     * @param b2cId The b2c id of the user
     * @returns A new session state
     */
    public createSessionState(isAuthenticated: boolean, oktaId: string, b2cId: string, email: string): SessionState {
        // Create a new session state
        let sessionState: SessionState = new SessionState();
        sessionState.isAuthenticated = isAuthenticated;
        sessionState.identityId = oktaId;
        sessionState.b2cId = b2cId;
        sessionState.email = email;
        sessionState.hasAcceptedPrivacy = true;
        // Return the session state
        return sessionState;
    }

    /**
     * Updates the session details from the token
     * @param tokenModel The token model details
     */
    public updateSessionFromToken(sessionState: SessionState, tokenModel: TokenModel, tenant: TenantModel | undefined, asset: AssetModel | undefined): SessionState {
        this.logDebug(this.updateSessionFromToken.name, 'Entering session update from token');
        // Decode the token
        let jwtHelper = new JwtHelperService();
		let decodedToken = jwtHelper.decodeToken(tokenModel.token);
        // Get the properties from the token
        let userId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        let email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
        let firstname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        let surname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
        let hasAcceptedPrivacy = decodedToken['Opex.Optix.HasAcceptedPrivacy'];
        let userType = decodedToken['Opex.Optix.UserType'];
        let roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        let savedTheme = decodedToken['Opex.Optix.Theme'];
        // Update the session
        sessionState.currentTenant = tenant;
        sessionState.currentAsset = asset;
        sessionState.userId = userId;
        sessionState.email = email;
        sessionState.firstname = firstname;
        sessionState.lastname = surname;
        sessionState.hasAcceptedPrivacy = (hasAcceptedPrivacy.toLowerCase() === "true");
        sessionState.userType = userType;
        sessionState.theme = savedTheme;
        sessionState.roles = roles;
        sessionState.jsonWebToken = tokenModel.token;
        sessionState.tokenExpiry = new Date(tokenModel.expiryDate);
        sessionState.refreshToken = tokenModel.refreshToken;

        this.logDebug(this.updateSessionFromToken.name, 'Exiting session update from token', [sessionState]);
        return sessionState;
    }

    /**
     * Gets the pathname from the window
     */
    public getPathname(): string {
        return window.location.pathname;
    }
}