import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RaygunErrorHandler } from './app-config/app.raygun.setup';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AppInitialiserComponent } from './app-initialiser.component';
import { SharedModule } from 'primeng/api';
import { HomeModule } from './modules/home/home.module';
import { ErrorInterceptor } from './core/utils/interceptors/error-interceptor';
import { AuthInterceptor } from './core/utils/interceptors/auth-interceptor';
import { CoreComponentsModule } from './core/components/core-components.module';
import { TitleStrategy } from '@angular/router';
import { AppTitleBuilder } from './core/utils/title-strategy/app-title-builder.strategy';
import { LogUpdateService } from './core/services/service-worker/log-update.service';
import { PromptUpdateService } from './core/services/service-worker/prompt-update.service';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import OktaAuth from '@okta/okta-auth-js';
import { OktaAuthModule } from '@okta/okta-angular';

/**
 * Okta Authentication Configuration
 */
const oktaAuth = new OktaAuth(environment.okta);

@NgModule({
  declarations: [
    AppComponent,
    AppInitialiserComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Main App Routing Module
    AppRoutingModule,
    // Okta Auth Module
    OktaAuthModule.forRoot({ oktaAuth }),
    // PrimeNG Shared Module
    SharedModule,
    // PWA Service Wroker
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Only Enable the service worker when the app is not in local dev
      // mode, we don't want the service worker interupting local development
      // see the readme for instructions on enabling the service worker
      enabled: (environment.mode !== 'local'),
      //enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // Optix Core Components Module
    CoreComponentsModule,
    // Optix Home Module
    HomeModule,
    //PrimeNg Modules
    ToastModule,
    ButtonModule
  ],
  providers: [
    HttpClientModule,
    // Optix HTTP Request Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // Raygun Error Catcher
    //{ provide: ErrorHandler, useClass: RaygunErrorHandler },
    //{ provide: TitleStrategy, useClass: AppTitleBuilder },
    // Service Worker Helpers
    LogUpdateService,
    PromptUpdateService
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
