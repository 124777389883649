import { Component } from '@angular/core';
import { AppComponentBase } from 'src/app/core/utils/base-components/app-component-base';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent extends AppComponentBase {
  constructor() {
    super();
  }
}
