import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TenantModel } from '../../models/global/clients/tenant-model';
import { AssetModel } from '../../models/tenant/location/asset-model';
import { UserService } from '../../services/global/clients/user.service';
import { ImpersonationService } from '../../services/session/impersonation.service';
import { AppComponentBase } from '../../utils/base-components/app-component-base';
import { ScreenService } from '../../services/screen.service';
import { BreakpointState } from '@angular/cdk/layout';

@Component({
  templateUrl: './app-header.component.html',
  selector: 'app-header'
})
export class AppHeaderComponent extends AppComponentBase implements OnInit, AfterViewInit {

  tenantCount: number = 0;
  asset: AssetModel | undefined;
  tenantAssets: AssetModel[] = [];

  public username: string = '';
  public items: MenuItem[] = [];
  public impersonationSession: boolean = false;
  public impersonationSessonEndTime!: Date;

  @Input() menuVisible: boolean = false;
  @Output() menuVisibleChange: EventEmitter<boolean> = new EventEmitter();

  public displayTermsDialog: boolean = false;
  public privacyViewed: boolean = false;
  public cookiesViewed: boolean = false;
  public termsViewed: boolean = false;
  public currentTenant: TenantModel | undefined;
  public navigationProperties: string[] = [""];
  public assetCount : number = 0;

  public isSmallMediumScreen: boolean = false;

  constructor(private sanitizer: DomSanitizer, private router: Router, private userService: UserService, 
    private impersonationService: ImpersonationService, private screenService: ScreenService) {
    super();

    this.items = [
      { 
        label: 'Theme', 
        icon: 'pi pi-palette', 
        id: 'theme-menu',
        items: [
          { label: 'Dark', command: () => { this.toggleTheme('default'); } },
          { label: 'Light', command: () => { this.toggleTheme('light'); } },
          { label: 'Blue', command: () => { this.toggleTheme('blue'); } },
        ]
      },
      { separator: true },
      { label: 'Logout', icon: 'pi pi-sign-out', command: () => { this.logout(); } }
    ];
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    let sessionSubscription = this.appOktaSessionService.sessionState$().subscribe(s => {
      this.sessionInfo = s;
      this.setThemeFromSession();
      this.loadPageData();
    });
    this.sessionStateSubscription.add(sessionSubscription);
  }

  ngAfterViewInit(): void {
      this.logDebug(this.ngAfterViewInit.name, 'Entering...');
      // Subscribe to if the screen size changes
      this.screenService.isBelowLg().subscribe((result: BreakpointState) => {
          this.isSmallMediumScreen = result.matches;
          if (!this.isSmallMediumScreen) {
            this.handleShowMenu();
          }
      });
      this.logDebug(this.ngAfterViewInit.name, 'Leaving...');
  }

  public override loadPageData(): void {
    // Get the session info
    this.tenantCount = ((this.sessionInfo.tenants) ? this.sessionInfo.tenants.length : 0);
    this.currentTenant = this.sessionInfo.currentTenant;
		this.navigationProperties = ['main', this.currentTenant == null ? '' : this.currentTenant.tenancyName, 'dashboard'];
    this.assetCount = this.sessionInfo.assets.length;

    if (this.sessionInfo.isAuthenticated) {
      this.username = `${this.sessionInfo.firstname ?? ''} ${this.sessionInfo.lastname ?? ''}`;
      this.displayTermsDialog = (this.username.trim() !== '') && !this.sessionInfo.hasAcceptedPrivacy;
      this.impersonationSession = this.sessionInfo.originalUserSessionState !== undefined;

      // Change the username text if the user is impersonating another user
      if (this.sessionInfo.originalUserSessionState) {
        this.username = `${this.sessionInfo.originalUserSessionState.firstname ?? ''} ${this.sessionInfo.originalUserSessionState.lastname ?? ''} as ${this.sessionInfo.firstname ?? ''} ${this.sessionInfo.lastname ?? ''}`;
        this.impersonationSessonEndTime = this.sessionInfo.tokenExpiry;
        // Since the session is being impersonated, add an option to return to original session
        if (this.items.findIndex(e => e.label === 'End Impersonation') === -1) {
          this.items.push({ separator: true });
          this.items.push({ label: 'End Impersonation', icon: 'pi pi-power-off', command: () => { this.endImpersonation(); } });
        }
      }
      else {
        // Remove the end impersonation options from the menu if present
        if (this.items.length > 3) {
          this.items.splice(3, 2);
        }
      }
    }

    this.tenantAssets = this.sessionInfo.assets ?? [];
    this.asset = this.sessionInfo.currentAsset;
  }

  public handleShowMenu() {
    // If the screen is large, animate the main content to the right
    if (!this.isSmallMediumScreen) {
      this.screenService.applyMarginForMenuPanel();
    }
    // Show the menu
    this.menuVisible = true;
    this.menuVisibleChange.emit(this.menuVisible);
  }

  public getSafeLogo(tenant: TenantModel): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl("data:" + tenant.logoFileType + ";base64, " + tenant.logo);
  }

  public logout() {
    //this.appSessionService.logout(this.document.location.origin);
    this.appOktaSessionService.logout();
  }

  public onAssetChange() {
    this.logDebug(this.onAssetChange.name, 'asset selection changed', [this.asset]);
    if (this.asset !== undefined) {
      var currentAsset = this.appOktaSessionService.sessionState().currentAsset;
      var assetName = currentAsset?.name;
      assetName = assetName?.replace(' ', '%20');
      // Set the router reuse strategy to ensure the page gets reloaded, otherwise Angular gets too smart
      // for itself and realises that just the asset param has changed in the route and doesn't reinitialise
      // the page/component
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      //this.router.onSameUrlNavigation = 'reload';
    
      this.router.navigateByUrl(this.router.url.replace(assetName ?? '', this.asset.name), { onSameUrlNavigation: 'reload' });
    }
  }

  public acceptedTermsAndPrivacy(): void {
    this.logDebug(this.acceptedTermsAndPrivacy.name, 'accepting terms and privacy');
    // Accept the terms and privacy
    if (this.privacyViewed && this.cookiesViewed && this.termsViewed) {
      this.userService.acceptTermsAndPrivacy().subscribe({
        next: (response) => {
          // Update the session state with the acceptance
          this.appOktaSessionService.setAcceptTermsAndPrivacy();
          this.displayTermsDialog = false;
        },
      });
    }
  }

  public endImpersonation(): void {
    this.logDebug(this.endImpersonation.name, 'ending impersonation');
    this.impersonationService.endImpersonationSession();
  }

  public toggleTheme(theme: string): void {
    this.logDebug(this.toggleTheme.name, 'changing theme', [theme]);
    this.spinner.show();
    // Update the theme
    this.userService.updateUserTheme(theme).subscribe({
      next: (response) => {
        // Update the application theme
        this.appOktaSessionService.setTheme(theme);
        this.spinner.hide();
      },
    });
  }
  
  public setThemeFromSession(): void {
    this.logDebug(this.setThemeFromSession.name, 'changing theme');
    // Update the application theme
    this.themeService.switchTheme(this.sessionInfo.theme);
  }
}
