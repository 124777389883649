import { Component } from '@angular/core';

@Component({
  templateUrl: './app-footer.component.html',
  selector: 'app-footer'
})
export class AppFooterComponent{
  
    year = (new Date()).getUTCFullYear();
    version = require('../../../../../package.json').version;
  
    constructor() { }
}
