import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSpinnerComponent } from './core/components/app-spinner/app-spinner.component';
import { OptixComponentBase } from './core/utils/base-components/optix-component-base';
import { AppOktaSessionService } from './core/services/session/app-okta-session.service';
import { Observable, filter, map } from 'rxjs';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';

@Component({
  selector: '',
  templateUrl: './app-initialiser.component.html'
})
export class AppInitialiserComponent extends OptixComponentBase implements OnInit {
  
  private authenticated$!: Observable<boolean>;

  constructor(private router: Router, private spinner: AppSpinnerComponent, private appOktaSesionService: AppOktaSessionService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public authService: OktaAuthStateService) {
    super();
    this.logDebug(this.constructor.name, 'loaded');
  }

  public async ngOnInit(): Promise<void> {
    this.logDebug(this.ngOnInit.name, 'initialised');
    
    this.spinner.show();
    
    // Get the authenticated state
    let authenticated$ = this.authService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    
    // Subscribe to the authenticated state and initialise the session when authenticated
    authenticated$.subscribe(async (authenticated: boolean) => {
      if (authenticated) {
        // Initialise the session state
        await this.appOktaSesionService.init(this.router.routerState.root.snapshot, this.router.routerState.snapshot);
        this.spinner.hide();
      }
    });
  }
}
