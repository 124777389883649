import { inject, Injectable, Injector } from "@angular/core";
import { Observable, lastValueFrom, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { TokenModel } from "src/app/core/models/authentication/token.model";
import { environment } from "src/environments/environment";
import { ServiceBase } from "../../service-base";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class SessionTokenService extends ServiceBase {

    private router: Router = inject(Router);

    private localStorageApiTokenKey: string = "OptixApiToken";
    
    public tokenDetails: TokenModel | undefined;

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Generates a new auth token from the server
     */
    public async generateAuthToken(identityId: string, b2cId: string, email: string | undefined, tenancyName: string, assetId?: string): Promise<TokenModel> {
        this.logDebug(this.generateAuthToken.name, "Getting new token", [tenancyName, assetId]);
        // Set the details to be sent to the server for the token
        var authModel = {
            applicationId: environment.optixApi.applicationId,
            applicationSecret: environment.optixApi.applicationSecret,
            identityId: identityId,
            b2cId: b2cId,
            email: email,
            tenancyName: tenancyName,
            assetId: assetId
        };
        // Make the request to the server
        let token = await lastValueFrom(this.getAuthToken(authModel));
        // Make the expiry date a date
        token.expiryDate = new Date(token.expiryDate);
        // Store the token in local storage
        localStorage.setItem(this.localStorageApiTokenKey, JSON.stringify(token));

        this.logDebug(this.generateAuthToken.name, 'Token retrieved', [this.tokenDetails]);
        return token;
    }

    /**
     * Gets the authentication token
     * @param authModel The authentication model to use to get the token
     * @returns The authentication token
     */
    public getAuthToken(authModel: any): Observable<TokenModel> {
        this.logDebug(this.getAuthToken.name, "Getting new token", [authModel]);
        return this.httpClient.post<TokenModel>(`${this.baseUrl}v1/authentication`, authModel)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = `Error Code: ${error.status},  Message: ${error.error}`;
                    if (error.status === 401) {
                        // Redirect the user to the unauthorised page
                        this.router.navigate(['unauthorised']);
                    }
                    console.log(errorMsg);
                    return throwError(() => new Error(`Failed to get token - ${errorMsg}`));
                })
            );
    }

    /**
     * Gets a refreshed jwt
     * @param jwt The current jwt for the user.
     * @param refreshToken The refresh token used to validate the current user
     */
    public refreshAuthToken(jwt: string, refreshToken: string) : Observable<TokenModel> {
        this.logDebug(this.refreshAuthToken.name, 'refreshing token...');
        // Create the model for generating the token
        let refreshModel = {
            token: jwt,
            refreshToken: refreshToken
        }
        // Get a new refreshed jwt
        return this.httpClient.post<TokenModel>(`${this.baseUrl}v1/authentication/refresh`, refreshModel)
                .pipe(
                    tap(data => {
                        this.tokenDetails = new TokenModel(
							data.token,
							new Date(data.expiryDate),
							data.refreshToken
						);
                        this.logDebug(this.refreshAuthToken.name, 'token retrieved', [this.tokenDetails]);
                        localStorage.setItem(this.localStorageApiTokenKey, JSON.stringify(this.tokenDetails));
                    })
                );
    }
}