import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-environment-banner',
  templateUrl: './environment-banner.component.html',
  providers: [MessageService]
})
export class EnvironmentBannerComponent implements OnInit {

  displayMessage: boolean = false;
  messageText!: string;

  constructor() { }

  ngOnInit() {    
    if (environment.mode !== 'prod') {
      this.displayMessage = true;
      this.messageText = 'You are currently in ' + environment.mode + '!';
    }
  }

}
