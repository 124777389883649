import { formatDate } from '@angular/common';
import { Size } from 'ngx-spinner';

/**
 * The purpose of the Optix Component base is to be a common base class for all other
 * base components, providing helper methods that can be used by all.
 */
export abstract class OptixComponentBase {

    private locale: string = "en-GB";

    public panelLoaderIcon: string = 'ball-clip-rotate-multiple';
    public panelLoaderSize: Size = 'medium';
    public panelLoaderColour: string = '#7cae15';

    constructor() { }

    /**
     * Writes a debug level message to the console.
     * @param method The method writing the debug message.
     * @param message The message to printed in the console.
     * @param optionalParams Any params to be included in the message.
     */
    public logDebug(method: string, message: string, optionalParams?: any[]) {
        let instance: any = this.constructor;
        let logMessageDate: string = formatDate(Date.now(),'yyyy-MM-dd HH:mm:ss', this.locale);

        if (optionalParams)
            console.debug(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`, optionalParams);
        else
            console.debug(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`);
    }

    /**
     * Writes a info level message to the console.
     * @param method The method writing the info message.
     * @param message The message to printed in the console.
     * @param optionalParams Any params to be included in the message.
     */
     public logInfo(method: string, message: string, optionalParams?: any[]) {
        let instance: any = this.constructor;
        let logMessageDate: string = formatDate(Date.now(),'yyyy-MM-dd HH:mm:ss', this.locale);

        if (optionalParams)
            console.log(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`, optionalParams);
        else
            console.log(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`);
    }

    /**
     * Writes a error level message to the console.
     * @param method The method writing the error message.
     * @param message The message to printed in the console.
     * @param optionalParams Any params to be included in the message.
     */
     public logError(method: string, message: string, optionalParams?: any[]) {
        let instance: any = this.constructor;
        let logMessageDate: string = formatDate(Date.now(),'yyyy-MM-dd HH:mm:ss', this.locale);

        if (optionalParams)
            console.error(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`, optionalParams);
        else
            console.error(`[${logMessageDate}] : ${instance.name} : ${method} : ${message}`);
    }
}