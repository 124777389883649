import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { concat, first, interval } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LogUpdateService {
	constructor(private appRef: ApplicationRef, private updates: SwUpdate, private messageService: MessageService) {
		// Only setup service worker updates if it has been enabled
		if (updates.isEnabled) {
			// Setup the interval to check for updates
			this.setupIntervalToCheckForUpdates();
			// Subscribe to updates
			this.subscribeToVersionUpdates();
		}
	}

	protected setupIntervalToCheckForUpdates(): void {
		// Subscribe to app updates
		const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    	// Default the interval to 2 hours
		let interval$ = interval(2 * 60 * 60 * 1000);
		// If the environment is dev, change the interval to 30 minutes since it gets updated more often
		if (environment.mode === 'local')
			interval$ = interval(30 * 60 * 1000);

    	const appUpdate$ = concat(appIsStable$, interval$);

    	appUpdate$.subscribe(async () => {
      		try {
        		const updateFound = await this.updates.checkForUpdate();
        		console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      		} catch (err) {
        		console.error('Failed to check for updates:', err);
      		}
    	});
	}

	protected subscribeToVersionUpdates(): void {
		// Subscribe to version updates and log
		this.updates.versionUpdates.subscribe((evt) => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`Downloading new app version: ${evt.version.hash}`);
					this.messageService.add({
						severity: 'info', 
						summary: 'App Update', 
						detail: 'A new version of the app has been detected, this will be downloaded and installed in the background, you do not need to do anything.',
						key: 'UpdateInfo',
						life: 10000
					});
					break;
				case 'VERSION_READY':
					console.log(`Current app version: ${evt.currentVersion.hash}`);
					console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
					break;
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
					break;
			}
		});
	}
}
