import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * Switches the theme of the application
     * @param theme The name of the selected theme
     */
    public switchTheme(theme: string) {
        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

        if (themeLink)
            themeLink.href = `${theme}.css`;
    }

    public getTheme(): string {
        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

        if (themeLink) {
            return themeLink.href.replace('.css', '').replace(/^.*[\\\/]/, '');
        }

        return 'default';
    }
}