import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subscription, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsLoginModel } from '../../models/analytics/analytics-login.model';
import { StartAnalyticsPageViewModel, StartAnalyticsPageViewResponseModel } from '../../models/analytics/start-analytics-page-view.model';
import { StartAnalyticsSessionModel } from '../../models/analytics/start-analytics-session.model';
import { TokenModel } from '../../models/authentication/token.model';
import { SessionState } from '../../models/session/session-state.model';
import { AppOktaSessionService } from '../session/app-okta-session.service';

const localStorageSessionIdKey: string = 'Opex.Optix2.Analytics.SessionId';
const localStoragePageViewIdKey: string = 'Opex.Optix2.Analytics.PageViewId';
const localStorageApiTokenKey: string = 'Opex.Optix2.Analytics.Token';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public http: HttpClient;
    protected router: Router;

    public baseApiUrl: string;
    public baseApiVersion: string = 'v2';
    private applicationId: string;
    private apiKey: string;

    public sessionInfo!: SessionState;

    constructor() {
        this.http = new HttpClient(inject(HttpBackend));
        this.router = inject(Router);

        this.baseApiUrl = environment.analytics.baseUrl;
        this.applicationId = environment.analytics.applicationId;
        this.apiKey = environment.analytics.apiKey;

        inject(AppOktaSessionService).sessionState$().subscribe(s => {
            // Record the change of session info
            this.sessionInfoUpdates(s);
        });
    }

    /**
     * Gets the Http Header options.
     * @returns The Http Header options for connecting to the analytics api.
     */
    public getHttpHeaderOptions(token: string) {
        // Create the headers for the request
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + token,
        });
        // Return the headers
        return { headers: headers };
    }

    /**
     * Gets a token for the api.
     * @returns The newly generated token
     */
    public getNewAnalyticsToken(): Observable<TokenModel> {
        // Attempt to get the token details from local storage
        var localStorageItem = localStorage.getItem(localStorageApiTokenKey);
        if (localStorageItem) {
            // We have the token details, check that it has not expired, if it has, we need a new one
            let tokenDetails = JSON.parse(localStorageItem) as TokenModel;
            let currentTime = new Date();
            let tokenExpiry = new Date(tokenDetails.expiryDate);
            if (tokenExpiry > currentTime) {
                return of(tokenDetails);
            }
        }

        // Token hasn't been set, or has expired, so get a new token
        var loginModel = new AnalyticsLoginModel();
        loginModel.id = this.applicationId;
        loginModel.apiKey = this.apiKey;

        let authenticationUrl = this.baseApiUrl + this.baseApiVersion + '/Authentication/Authenticate';
        // Make the request
        return this.http.post<TokenModel>(authenticationUrl, loginModel).pipe(
            tap((data) => {
                localStorage.setItem(localStorageApiTokenKey, JSON.stringify(data));
            })
        );
    }

    /**
     * Records the analytics if session info has changed
     * @param newSessionInfo The new session info details.
     */
    public sessionInfoUpdates(newSessionInfo: SessionState): void {
        // Update the session info
        let originalSessionInfo = { ...this.sessionInfo };
        this.sessionInfo = newSessionInfo;
        // If the tenant or asset has changed, record in the user analytics, or session hadn't
        // previously been set
        if (!originalSessionInfo ||
            this.sessionInfo.currentTenant !== originalSessionInfo.currentTenant ||
            this.sessionInfo.currentAsset !== originalSessionInfo.currentAsset) {
            this.recordUserAnalytics(true);
        }
    }

    /**
     * Records the user analytics
     */
    public recordUserAnalytics(includePageData: boolean, additionalPageData: any = {}): void {
        if (!this.sessionInfo.email) return;

        //console.trace('Navigated to: ' + this.router.url);

        let root = this.router.routerState.snapshot.root;

        let pageRoute: string[] = [];
        let pageData: any = {};
        // Add the asset the user is on by default
        if (this.sessionInfo.currentAsset) pageData['asset'] = this.sessionInfo.currentAsset.name;

        try {
            while (root) {
                if (includePageData) {
                    // Get the route data
                    for (const paramMapKey of root.paramMap.keys) {
                        if (paramMapKey !== 'tenant' && paramMapKey !== 'asset') {
                            console.trace(paramMapKey + ': ' + root.paramMap.get(paramMapKey));
                            if (root.paramMap.get(paramMapKey)) pageData[paramMapKey] = root.paramMap.get(paramMapKey);
                        }
                        else {
                            let param = root.paramMap.get(paramMapKey);
                            if (param) {
                                let rootParamIndex = pageRoute.findIndex(e => e.indexOf(param ?? '') > -1);
                                if (rootParamIndex > -1)
                                    pageRoute.splice(rootParamIndex, 1);
                            }
                        }
                    }
                }

                if (root.children && root.children.length) {
                    root = root.children[0];
                    // Get the url if it is not the app or main portions of the url and there are not params
                    if (root.url.toString() !== 'main' && root.url.toString() !== '')
                        pageRoute.push(root.url.toString());
                } else {
                    break;
                }
            }
        } catch (ex: any) {
            console.log('Error occurred getting page data: ' + ex.message);
        }

        // Add the additional page data to the page data collection
        for (var [key, value] of Object.entries(additionalPageData)) {
            pageData[key] = value;
        }

        // Record the start of the page view
        this.startAnalyticsPageView(pageRoute.join(' / '), JSON.stringify(pageData));
    }

    /**
     * Records the start of the session.
     * Will store the Session Id in session storage for future use.
     */
    public startAnalyticsSession(): void {
        if (!this.sessionInfo.email) return;

        // Create the model for passing to the request
        let sessionDetails = new StartAnalyticsSessionModel();
        sessionDetails.username = this.sessionInfo.email;
        // If the session is impersonated, record against the original user
        if (this.sessionInfo.originalUserSessionState && this.sessionInfo.originalUserSessionState.email)
            sessionDetails.username = this.sessionInfo.originalUserSessionState.email;

        if (this.sessionInfo.currentTenant)
            sessionDetails.tenant = this.sessionInfo.currentTenant.name;
        sessionDetails.referrer = document.referrer;

        let sessionStartUrl = this.baseApiUrl + this.baseApiVersion + "/Session/Start";
        // Start the session, and record the response in local storage
        this.getNewAnalyticsToken().subscribe(token => {
            this.postToAnalyticsService<string>(token.token, sessionStartUrl, sessionDetails)
                .subscribe((data: string) => {
                    // Store the session id in local storage
                    sessionStorage.setItem(localStorageSessionIdKey, data);
                });
        });
    }

    /**
     * Records the end of the session and clears the local storage.
     */
    public endAnalyticsSession(): void {
        // Get the session id
        let sessionId = sessionStorage.getItem(localStorageSessionIdKey);
        if (sessionId !== null) {
            let sessionEndUrl = this.baseApiUrl + this.baseApiVersion + "/Session/End/" + sessionId;
            // End the session
            console.log("Ending session in analytics service.");
            this.getNewAnalyticsToken().subscribe(token => {
                this.postToAnalyticsService<string>(token.token, sessionEndUrl, null)
                    .subscribe(() => {
                        // Remove the item from session storage
                        sessionStorage.removeItem(localStoragePageViewIdKey);
                        sessionStorage.removeItem(localStorageSessionIdKey);
                    });
            });
        }
    }

    /**
     * Records the start of the page view.
     * Will store the returned Session Id and Page View Id in local storage.
     * @param pageTitle The title of the page the user is starting to view.
     * @param pageData The data passed to the page.
     */
    public startAnalyticsPageView(pageTitle: string, pageData: string): void {
        if (!this.sessionInfo.email)
            return;

        // Get the session id
        let sessionId = sessionStorage.getItem(localStorageSessionIdKey);
        // Get the previous page view id, if it exists
        let previousPageViewId = sessionStorage.getItem(localStoragePageViewIdKey);

        // Create the model for passing to the request
        let pageViewDetails = new StartAnalyticsPageViewModel();
        pageViewDetails.sessionId = sessionId ?? undefined;
        pageViewDetails.pageTitle = pageTitle;
        pageViewDetails.pageData = pageData;
        pageViewDetails.previousPageViewId = previousPageViewId ?? undefined;
        pageViewDetails.username = this.sessionInfo.email ?? 'unknown';
        // If the session is impersonated, record against the original user
        if (this.sessionInfo.originalUserSessionState && this.sessionInfo.originalUserSessionState.email)
            pageViewDetails.username = this.sessionInfo.originalUserSessionState.email;
        if (this.sessionInfo.currentTenant)
            pageViewDetails.tenant = this.sessionInfo.currentTenant.name;

        // Start the page view
        let pageViewStartUrl = this.baseApiUrl + this.baseApiVersion + "/PageView/Start";
        // Start the page view, and record the response in local storage
        this.getNewAnalyticsToken().subscribe(token => {
            this.postToAnalyticsService<StartAnalyticsPageViewResponseModel>(token.token, pageViewStartUrl, pageViewDetails)
                .subscribe((data) => {
                    // Store the session id and page view id in local storage
                    sessionStorage.setItem(localStorageSessionIdKey, data.sessionId);
                    sessionStorage.setItem(localStoragePageViewIdKey, data.pageViewId);
                });
        });
    }

    /**
     * Posts the data to the analytics service
     * @param token The authentication token
     * @param url The url to post to
     * @param data The data to be posted
     * @returns The response
     */
    public postToAnalyticsService<Type>(token: string, url: string, data: any): Observable<Type> {
        // Post to the analytics service
        return this.http.post<Type>(url, data, this.getHttpHeaderOptions(token));
    }
}
