
<app-spinner></app-spinner>

<app-header></app-header>

<section>
    <router-outlet></router-outlet>
</section>

<app-footer></app-footer>

<p-toast key="UpdateInfo"></p-toast>
<p-toast key="UpdateNow">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-12">
                    <button type="button" pButton (click)="reloadApp()" label="Reload Now" class="p-button-rounded p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>