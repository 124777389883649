import { Inject, inject, Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ScreenService {
    
    private observer: BreakpointObserver = inject(BreakpointObserver);

    constructor(@Inject(DOCUMENT) private document: Document) {}
    
    /**
     * Gets if the screen size is below the small breakpoint.
     * @returns Observable<BreakpointState>
     */
    public isBelowSm(): Observable<BreakpointState> {
        return this.observer.observe(['(max-width: 575px)']);
    }
    
    /**
     * Gets if the screen size is below the medium breakpoint.
     * @returns Observable<BreakpointState>
     */
    public isBelowMd(): Observable<BreakpointState> {
        return this.observer.observe(['(max-width: 767px)']);
    }
    
    /**
     * Gets if the screen size is below the large breakpoint.
     * @returns Observable<BreakpointState>
     */
    public isBelowLg(): Observable<BreakpointState> {
        return this.observer.observe(['(max-width: 991px)']);
    }
    
    /**
     * Gets if the screen size is below the extra large breakpoint.
     * @returns Observable<BreakpointState>
     */
    public isBelowXl(): Observable<BreakpointState> {
        return this.observer.observe(['(max-width: 1199px)']);
    }

    /**
     * Applies a margin to the header, section and footer to allow for the menu panel.
     */
    public applyMarginForMenuPanel(): void {
        let header = document.getElementsByTagName('header')[0];
        if (header) {
            header.style.marginLeft = '320px';
        }
        let section = document.getElementsByTagName('section')[0];
        if (section) {
            section.style.marginLeft = '320px';
        }
        let footer = document.getElementsByTagName('footer')[0];
        if (footer) {
            footer.style.paddingLeft = '320px';
        }
    }

    /**
     * Removes a margin from the header, section and footer to allow for the menu panel.
     */
    public removeMarginForMenuPanel(): void {
        let header = document.getElementsByTagName('header')[0];
        if (header) {
            header.style.marginLeft = '0px';
        }
        let section = document.getElementsByTagName('section')[0];
        if (section) {
            section.style.marginLeft = '0px';
        }
        let footer = document.getElementsByTagName('footer')[0];
        if (footer) {
            footer.style.paddingLeft = '0px';
        }
    }
}