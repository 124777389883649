import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { SupportModel } from "src/app/core/models/global/clients/support.model";
import { ServiceBase } from "../../service-base";
import { RequestAccessModel } from "src/app/core/models/global/clients/request-access.model";

@Injectable({
    providedIn: 'root'
})
export class SupportService extends ServiceBase {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates the support request.
     * @param supportModel The support details.
     * @returns Observable with the new tenant.
     */
    public submitSupportRequest(supportModel: SupportModel): Observable<SupportModel> {
        let formData = new FormData();
        formData.append('subject', supportModel.subject ?? '');
        formData.append('supportType', supportModel.supportType ?? '');
        formData.append('message', supportModel.message ?? '');

        if (supportModel.attachments) {
			for (var i = 0; i < supportModel.attachments.length; i++) {
				formData.append('attachments', supportModel.attachments[i]);
			}
		}

        return this.httpQueue.post<SupportModel>(`${this.baseUrl}v1/Support/SubmitSupportRequest`, formData);
    }

    /**
     * Creates the access request.
     * @param supportModel The support details.
     * @returns Observable with the new tenant.
     */
    public submitAccessRequest(accessRequest: RequestAccessModel): Observable<RequestAccessModel> {
        return this.httpQueue.post<RequestAccessModel>(`${this.baseUrl}v1/Support/SubmitAccessRequest`, accessRequest);
    }
}