import { Component, Injector } from '@angular/core';
import { AppComponentBase } from 'src/app/core/utils/base-components/app-component-base';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css'],
})
export class CookieComponent extends AppComponentBase {
  constructor() {
    super();
  }
}
