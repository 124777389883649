@if (isSmallMediumScreen) {
    <p-sidebar [(visible)]="menuVisible" [baseZIndex]="100000" (onHide)="onHideSideBar()" class="sidebar-menu">
        <ng-template pTemplate="header">
            <brand-logo logoHeight="32"></brand-logo>
        </ng-template>
        <p-panelMenu [model]="menuItems"></p-panelMenu>
    </p-sidebar>
}
@else {
    <p-sidebar [(visible)]="menuVisible" [modal]="false" (onHide)="onHideSideBar()" class="sidebar-menu">
        <ng-template pTemplate="header">
            <brand-logo logoHeight="32"></brand-logo>
        </ng-template>
        <p-menu [model]="menuItems"></p-menu>
    </p-sidebar>
}
